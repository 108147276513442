import {FC, ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Alert, Button, Card, Col, Form, Input, message, Modal, notification, Row, Select, Typography} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {useMutation, useQuery, useQueryClient, UseQueryResult} from "@tanstack/react-query";
import {
    getHomeofficeOptions,
    getJobById,
    getJobTypes,
    getStripeUrl,
    getTargetGroupOptions,
    getWorkloadOptions,
    updateJobDemand
} from "../../services/api";
import {GeocodedMap} from "../../components/Map";
import {IJobDetails, ILocation, JobTypeOption} from "../../services/types.ts";
import {AddressModal} from "../../components/AddressModal.tsx";
import {AuthContext} from "../../context/AuthContext.tsx";
import {PageTitle} from "candidate/src/components/PageTitle.tsx";
import {Loader} from "../../components/Loader.tsx";
import {useMediaQuery} from "@uidotdev/usehooks";
import {WhereWrapper} from "candidate/src/pages/jobDetails/JobDetails.tsx";
import {getToken} from "../../util/tokenCookies.ts";

const {Title, Text} = Typography;

const modalTitles: { [key: string]: string } = {
    title: 'Stellentitel',
    description: 'Beschreibung der offenen Stelle',
    target_group_label: 'Zielgruppe der Stellenanzeige',
    homeoffice_label: 'Homeoffice Möglichkeiten',
    job_type_label: 'Stellentyp',
    work_load_label: 'Arbeitspensum',
}

export const JobDetails: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {user} = useContext(AuthContext);
    const {id = ""} = useParams<{ id: string }>();
    const [editField, setEditField] = useState<keyof IJobDetails | null>(null);
    const [editValue, setEditValue] = useState<string | undefined>("");
    const [editWorkTypeAndLoad, setEditWorkTypeAndLoad] = useState<{
        job_type_label?: string,
        work_load_label?: string
    } | null>(null);
    const queryClient = useQueryClient()

    const [isLocationEditorOpen, setIsLocationEditorOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<ILocation | null>(null);
    const [isPublishModalVisible, setIsPublishModalVisible] = useState(false);
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");

    const isPaymentSuccess = new URLSearchParams(location.search).get('payment') === 'success';

    const {data, isLoading, error, refetch}: UseQueryResult<IJobDetails, Error> = useQuery({
        queryKey: ['jobs', id],
        queryFn: () => getJobById(id),
        enabled: !!id,
    });

    const {data: jobTypes}: UseQueryResult<JobTypeOption[], Error> = useQuery({
        queryKey: ['jobTypes'],
        queryFn: getJobTypes,
    });

    const {data: homeofficeOptions}: UseQueryResult<JobTypeOption[], Error> = useQuery({
        queryKey: ['homeofficeOptions'],
        queryFn: getHomeofficeOptions,
    });

    const {data: workloadOptions}: UseQueryResult<JobTypeOption[], Error> = useQuery({
        queryKey: ['workloadOptions'],
        queryFn: getWorkloadOptions,
    });

    const {data: targetGroupOptions}: UseQueryResult<JobTypeOption[], Error> = useQuery({
        queryKey: ['targetGroupOptions'],
        queryFn: getTargetGroupOptions,
    });

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get('payment') === 'success') {
            setIsPublishModalVisible(true);
            // Clean up the URL to remove the parameter
            const newUrl = `${location.pathname}${location.search.replace('?payment=success', '')}`;
            window.history.replaceState({}, '', newUrl);
        }
    }, [location]);

    const updateMutation = useMutation({
        mutationFn: (updatedData: Partial<IJobDetails>) => updateJobDemand(id, updatedData),
        onSuccess: () => {
            message.success('Eingabe erfolgreich gespeichert');
            setIsPublishModalVisible(prevState => {
                if (prevState) {
                    navigate('/jobs')
                }
                return false
            })
            queryClient.invalidateQueries({queryKey: ['jobs', id]})
            queryClient.invalidateQueries({queryKey: ['user']})
            refetch();
        },
        onError: (error) => {
            message.error(`Die Stellenausschreibung konnte nicht aktualisiert werden: ${error}`);
        },
    });

    const handleEdit = (field: keyof IJobDetails, value: string) => {
        if (field === 'job_type_label' || field === 'work_load_label') {
            setEditWorkTypeAndLoad({
                job_type_label: field === 'job_type_label' ? value : data?.job_type_label,
                work_load_label: field === 'work_load_label' ? value : data?.work_load_label,
            });
        } else {
            setEditField(field);
            setEditValue(value === "" ? undefined : value);
        }
    };

    const handleSave = useCallback(() => {
        if (editField && data) {
            const updatedData = {
                ...data,
                [editField]: editValue,
            };
            updateMutation.mutate(updatedData);
            setEditField(null);
        } else if (editWorkTypeAndLoad && data) {
            const updatedData = {
                ...data,
                ...editWorkTypeAndLoad,
            };
            updateMutation.mutate(updatedData);
            setEditWorkTypeAndLoad(null);
        }
    }, [editField, editValue, data, updateMutation, editWorkTypeAndLoad]);

    const handleEditLocation = () => {
        setIsLocationEditorOpen(true);
    };

    const handleSaveLocation = useCallback((location: ILocation | null) => {
        if (location && data) {
            const updatedData = {
                ...data,
                search_location_label: location.label_client,
                search_location_id: location.id,
                regions_id: location.type === 'region_id' ? location.value || 0 : 0,
                postal_code: location.type !== 'region_id' ? location.value?.toString() || '' : '',
                radius: location.radius || 0,
            };
            updateMutation.mutate(updatedData);
            setSelectedLocation(location);
        }
        setIsLocationEditorOpen(false);
    }, [data, updateMutation]);

    const isJobActive = data?.status_label === 'aktiv'

    const actionButtonHandler = async () => {
        if (!isJobActive) {
            if ((user?.plan_type === "paid" && !user?._client?.isPrivate && ((user?.jobs_current_month ?? 0) < (user?.jobs_allowed_monthly ?? 0))) || (user?.jobs_current_month || 0) < (user?.privateJobsAllowed || 0)) {
                setIsPublishModalVisible(true);
            } else {
                if (
                    data?.id && user?._client?.isPrivate && (user?.jobs_current_month ?? 0) < (user?.privateJobsAllowed ?? 0)
                ) {
                    const authToken = getToken();
                    const stripeResponse = await getStripeUrl({
                        id: authToken,
                        product: "Private",
                        redirectPath: `jobs/${data.id}`
                    });
                    if (stripeResponse.url) {
                        window.location.href = stripeResponse.url;
                    } else {
                        notification.error({message: "Es ist ein Fehler beim Anlegen der Stellenanzeige aufgetreten."});
                    }
                } else {
                    navigate("/finances")
                }
            }
        } else {
            const updatedData = {
                ...data,
                job_demand_status_id: 3
            };
            updateMutation.mutate(updatedData);
        }
    }

    const handlePublishJob = () => {
        if (data) {
            const updatedData = {
                ...data,
                job_demand_status_id: 4
            };
            updateMutation.mutate(updatedData);
        }
    };


    if (isLoading) {
        return <Loader/>
    }

    if (error) return <Alert message="Error" description={error.message} type="error"/>;
    if (!data) return <Alert message="No job data found" type="warning"/>;

    const address = data.search_location_id === -2 ? "Berlin Germany" : data.postal_code ? data.search_location_label : data.city + " Germany";

    const displayAddress = data.search_location_id === -2 ? "Homeoffice" : data.search_location_label;
    const mainBtnText = isJobActive
        ? "Stelle nicht mehr veröffentlichen"
        : (user?.plan_type === "paid" && !user?._client?.isPrivate && ((user?.jobs_current_month ?? 0) < (user?.jobs_allowed_monthly ?? 0))) || ((user?.jobs_current_month ?? 0) < (user?.privateJobsAllowed ?? 0))
            ? "Stellenanzeige veröffentlichen"
            : user?._client?.isPrivate && ((user?.jobs_current_month ?? 0) >= (user?.privateJobsAllowed ?? 0))
                ? "Weiter zur Zahlung der Stellenanzeige"
                : "Jetzt höheren Plan buchen";

    return (
        <div>
            <PageTitle onMainBtnClick={actionButtonHandler}
                       mainBtnText={mainBtnText}>
                <div>
                    <Title level={4} style={{margin: 0}}>Offene Stelle <EditOutlined
                        onClick={() => handleEdit('title', data!.title)}/></Title>
                    <Title level={2} style={{margin: 0}}>{data.title}</Title>
                </div>
            </PageTitle>
            <Row gutter={[16, 16]}>
                <Col span={isMobile ? 24 : 12}>
                    <Card style={{marginBottom: '24px'}}>
                        <DetailItem title="Zielgruppe" content={data.target_group_label}
                                    onEdit={() => handleEdit('target_group_label', data!.target_group_label)}/>
                        <DetailItem title="Homeoffice - Möglichkeiten" content={data.homeoffice_label}
                                    onEdit={() => handleEdit('homeoffice_label', data!.homeoffice_label)}/>
                        <DetailItem
                            title="Stellentyp und -pensum"
                            content={<>
                                Arbeitspensum: {data.work_load_label} <br/>
                                Typ: {data.job_type_label}
                            </>
                            }
                            onEdit={() => handleEdit('job_type_label', data!.job_type_label)}
                        />
                    </Card>
                    <Card>
                        <Title level={5}>Stellenbeschreibung <EditOutlined
                            onClick={() => handleEdit('description', data!.description)}/></Title>
                        <Text style={{
                            textAlign: "justify",
                            display: "block"
                        }}><WhereWrapper>{data.description}</WhereWrapper></Text>
                    </Card>
                </Col>
                <Col span={isMobile ? 24 : 12}>
                    <Card title="Standort" extra={<EditOutlined onClick={handleEditLocation}/>}>
                        <Text>Wo?: {selectedLocation?.label || displayAddress}</Text>
                        <Alert
                            message={!selectedLocation?.label_client?.includes("Region:") ?
                                "Legen Sie für jeden Standort eine eigene Stellenanzeige an um alle Kandidaten zu erreichen" :
                                "Wählen Sie soweit möglich immer eine Postleitzahl und legen Sie für jeden Standort eine eigene Stellenanzeige an"
                            }
                            type="info"
                            showIcon
                            style={{marginTop: 8, marginBottom: 8}}
                        />
                        <GeocodedMap address={address}/>
                    </Card>
                </Col>
            </Row>

            <Modal
                title={`${editField === null ? 'Stellentyp und Arbeitspensum' : modalTitles[editField]}`}
                open={!!editField || !!editWorkTypeAndLoad}
                onOk={handleSave}
                okText="Speichern"
                cancelText="Abbrechen"
                onCancel={() => {
                    setEditField(null);
                    setEditWorkTypeAndLoad(null);
                }}
            >
                <Form layout="vertical">
                    {editField === 'description' && (
                        <Form.Item name="description" initialValue={editValue}
                                   rules={[{required: true, message: 'Please enter a description'}]}>
                            <Input.TextArea rows={12} onChange={(e) => setEditValue(e.target.value)}/>
                        </Form.Item>
                    )}
                    {editField === 'title' && (
                        <Form.Item name="title" initialValue={editValue}
                                   rules={[{required: true, message: 'Please enter a title'}]}>
                            <Input size={isMobile ? "large" : "middle"} onChange={(e) => setEditValue(e.target.value)}/>
                        </Form.Item>
                    )}
                    {editWorkTypeAndLoad && (
                        <>
                            <Form.Item name="job_type_label" initialValue={editWorkTypeAndLoad.job_type_label}>
                                <Select
                                    placeholder="Stellentyp auswählen"
                                    options={jobTypes}
                                    onChange={(value) => setEditWorkTypeAndLoad(prev => ({
                                        ...prev,
                                        job_type_label: value
                                    }))}
                                />
                            </Form.Item>
                            <Form.Item name="work_load_label" initialValue={editWorkTypeAndLoad.work_load_label}>
                                <Select
                                    placeholder="Arbeitspensum auswählen"
                                    options={workloadOptions}
                                    onChange={(value) => setEditWorkTypeAndLoad(prev => ({
                                        ...prev,
                                        work_load_label: value
                                    }))}
                                />
                            </Form.Item>
                        </>
                    )}
                    {editField === 'homeoffice_label' && (
                        <Form.Item name="homeoffice_label" initialValue={editValue}>
                            <Select
                                placeholder="Homeoffice Möglichkeiten auswählen"
                                options={homeofficeOptions}
                                onChange={(value) => setEditValue(value)}
                            />
                        </Form.Item>
                    )}
                    {editField === 'target_group_label' && (
                        <Form.Item name="target_group_label" initialValue={editValue}>
                            <Select
                                placeholder="Zielgruppe auswählen"
                                options={targetGroupOptions}
                                allowClear
                                onChange={(value) => setEditValue(value)}
                            />
                        </Form.Item>
                    )}
                </Form>
            </Modal>
            <AddressModal
                open={isLocationEditorOpen}
                onClose={() => setIsLocationEditorOpen(false)}
                onSave={handleSaveLocation}
                initialValue={null}
            />
            <Modal
                title="Bestätigen Sie bitte, dass Sie diese Stellenanzeige veröffentlichen möchten"
                open={isPublishModalVisible}
                onOk={handlePublishJob}
                onCancel={() => setIsPublishModalVisible(false)}
                okText="Veröffentlichen"
                footer={[
                    !isPaymentSuccess &&
                    <Button key="cancel" type="default" onClick={() => setIsPublishModalVisible(false)}>
                        Zurück
                    </Button>,
                    <Button key="ok" type="primary" onClick={handlePublishJob}>
                        Veröffentlichen
                    </Button>
                ]}
            >
                <p>Hinweis: Wenn Sie diese Stellenanzeige zum ersten mal veröffentlichen, werden alle darauf passenden
                    Kandidaten informiert. Stellen Sie daher sicher, dass die Stellenanzeige einen aussagekräftigen
                    Titel und eine gute Stellenbeschreibung hat.</p>
                {/*<Alert*/}
                {/*    message="Sie testen silvertalent gerade kostenlos und können Stellenanzeigen schalten. Um Bewerbungen sehen zu können, müssen Sie einen kostenpflichtigen Plan buchen. Jegliche externe Kontaktdaten werden daher aus Ihren Nachrichten und Stellenanzeigen entfernt."*/}
                {/*    type="info"*/}
                {/*    showIcon*/}
                {/*    style={{marginTop: 16}}*/}
                {/*/>*/}
            </Modal>
        </div>
    );
};

interface DetailItemProps {
    title: string;
    content: string | ReactNode
    onEdit: () => void;
}

const DetailItem: FC<DetailItemProps> = ({title, content, onEdit}) => (
    <Row justify="space-between" align="middle" style={{padding: '8px 0'}}>
        <Col>
            <Text strong>{title}</Text>
            <br/>
            <Text>{content}</Text>
        </Col>
        <Col>
            <EditOutlined onClick={onEdit}/>
        </Col>
    </Row>
);
