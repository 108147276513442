import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import { TrackJS } from 'trackjs';

TrackJS.install({
    token: "ae15333fcc1a4785b009ab9fe981f220",
    application: "client"
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
